const DIR_DATA = 'src/data'
const API_BASE = 'http://localhost:3000'
const ROUTES_RESOURCES_FROM_API = ['news']
const LINKS = {
	bandcamp: 'https://diespnea.bandcamp.com',
}

module.exports = {
	DIR_DATA,
	API_BASE,
	ROUTES_RESOURCES_FROM_API,
	LINKS,
}
