import React from 'react'
import { Link } from 'gatsby'
import { Menu, Layout } from 'antd'

import LogoMini from './LogoMini'
import { menuItems, getCurrentRouteID } from './MenuItems'

const Sider = (props) => (
	<Layout.Sider trigger={null} collapsible collapsed={true}>
		<Link to={'/'} className='ant-menu-link'>
			<LogoMini />
		</Link>

		<Menu
			theme='dark'
			mode='inline'
			defaultSelectedKeys={['0']}
			selectedKeys={getCurrentRouteID(props.pathName)}
		>
			{menuItems('sidebar', props.pathName)}
		</Menu>
	</Layout.Sider>
)

export default Sider
