import React from 'react'
import { Layout } from 'antd'

const Footer = () => (
	<Layout.Footer className='text-center'>
		@{new Date().getFullYear()} - Diespnea - All rights reserved
	</Layout.Footer>
)

export default Footer
