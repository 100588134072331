import React, { useState } from 'react'
import { Form, Input, Checkbox, Icon, Button } from 'antd'

import { API_BASE } from '../config'

const Newsletter = (props) => {
	const [loading, setLoading] = useState(false)
	const [accepted, setAccepted] = useState(false)
	const [status, setStatus] = useState()

	const texts = {
		title: 'Subscribe to our newsletter:',
		button: {
			subscribe: 'Subscribe',
			success: 'Thanks!',
			error: 'Error',
		},
		email: {
			placeholder: 'Insert your email',
			error: 'Email not valid.',
		},
		checkbox: {
			label: 'You agree to let use your email for the only purpose of sending you occasional news',
			error: 'Please, accept the agreement above.',
		},
	}

	const rules = {
		email: [{ required: true, message: texts.email.error, type: 'email' }],
		accepted: [
			{
				required: true,
				message: texts.checkbox.error,
				type: 'boolean',
				transform: (value) => value || undefined,
			},
		],
	}

	const handleSubmit = () => {
		setLoading(true)

		props.form.validateFields((err, values) => {
			console.log(values)
			const error = () => {
				setLoading(false)
				setStatus('danger')
				setTimeout(() => setStatus(null), 2000)
			}

			if (err) error()
			else {
				setTimeout(() => {
					fetch(`${API_BASE}/newsletters`, {
						method: 'POST',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ email: values.email }),
					})
						.then((res) => {
							if (res.status !== 200) throw new Error()
							setStatus('success')
						})
						.catch(() => {
							error()
						})
				}, 1000)
			}
		})
	}

	const handleSubmit2 = () => {
		const success = () => {
			setStatus('success')
			setTimeout(() => setStatus(null), 500)
		}
		const error = () => {
			setLoading(false)
			setStatus('danger')
			setTimeout(() => setStatus(null), 500)
		}
		fetch(`${API_BASE}/newsletters`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email: 'asd@asd.it' }),
		})
			.then((res) => {
				if (res.status !== 200) throw new Error()
				success()
			})
			.catch(() => {
				error()
			})
	}

	const { getFieldDecorator } = props.form

	return (
		<div>
			<h2>{texts.title}</h2>
			<Form
				onSubmit={(e) => {
					e.preventDefault()
					handleSubmit2()
				}}
				className='newsletter'
			>
				<Form.Item className='email'>
					{getFieldDecorator('email', { rules: rules.email })(
						<Input
							prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
							disabled={status === 'success'}
							placeholder={texts.email.placeholder}
						/>
					)}
					<Button
						type={status || 'primary'}
						loading={loading}
						disabled={status === 'success'}
						onClick={() => handleSubmit2()}
					>
						{status === 'success' && <Icon type='check-circle' />}
						{status === 'success' && texts.button.success}

						{status === 'danger' && <Icon type='close-circle' />}
						{status === 'danger' && texts.button.error}

						{!status && !loading && texts.button.subscribe}
					</Button>
				</Form.Item>

				<Form.Item>
					{getFieldDecorator('accepted', { rules: rules.accepted })(
						<Checkbox
							disabled={status === 'success'}
							checked={accepted}
							onClick={() => setAccepted(!accepted)}
						>
							{texts.checkbox.label}
						</Checkbox>
					)}
				</Form.Item>
			</Form>
		</div>
	)
}

const NewsletterForm = Form.create({ name: 'newsletter' })(Newsletter)
export default NewsletterForm
