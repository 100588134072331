import React from 'react'
import { Layout } from 'antd'
import { LINKS } from '../config'

import MainLayout from '../components/MainLayout'
import SEO from '../components/seo'
import Newsletter from '../components/Newsletter'

import YouTube from 'react-youtube'

const opts = {
	height: '300',
	width: '640',
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 0,
	},
}

const IndexPage = () => (
	<MainLayout>
		<SEO title='Home' />

		<h1 className='distanced'>
			<span className='symbols'>-:-</span>
			<span>DIESPNEA</span>
			<span className='symbols'>-:-</span>
		</h1>

		<Layout.Content>
			<div class='yt'>
				<YouTube videoId='mZh-dYOcdUw' opts={opts} />
			</div>
			<br></br>
			<p>
				The <strong>DIESPNEA</strong> project stems from the spiritual association of Wolke and
				Anxitudo who, recognizing deep{' '}
				<strong>
					<i>Black Metal</i>
				</strong>{' '}
				roots in common, decided to blend their creative freedom together.
			</p>
			<p>
				The roles of the members are neither defined nor definitive, this is to guarantee a greater
				artistic/spiritual freedom which, starting from black metal foundations, seeks to overcome
				itself and experiment.
			</p>
			<p>
				<strong>DIESPNEA</strong> <u>rejects</u> interviews, or any other type of human contact,
				with the exception of labels, distros, music distribution and production.
				<br></br>
				<strong>DIESPNEA</strong> is uniquely art.
			</p>
			<p>
				For these reasons the only source of information will be this website and the newsletter;
				<br></br>
				<a target='_blank' rel='noopener noreferrer' href={LINKS.bandcamp}>
					Bandcamp
				</a>{' '}
				for releases.
			</p>
			<p className='text-center'>-:-</p>
		</Layout.Content>

		{/* <Layout.Content className='layout-newsletter'>
			<Newsletter />
		</Layout.Content> */}
		<br></br>
	</MainLayout>
)

export default IndexPage
