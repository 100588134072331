/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import { Layout as AntLayout } from 'antd'

import Header from './Header'
import Sider from './Sider'
import Footer from './Footer'

import 'antd/dist/antd.css'
import '../styles/style.scss'

const duration = 0.5

const variants = {
	initial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration,
			delay: duration,
			when: 'beforeChildren',
		},
	},
	exit: {
		opacity: 0,
		transition: { duration },
	},
}

const Layout = ({ children }) => {
	return (
		<AntLayout>
			<Header />
			<Sider />
			<AnimatePresence>
				<motion.div
					key={1}
					className='layout'
					variants={variants}
					initial='initial'
					animate='enter'
					exit='exit'
					id='main'
				>
					{children}
					<Footer />
				</motion.div>
			</AnimatePresence>
		</AntLayout>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
