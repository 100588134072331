import React from 'react'
import { Menu, Layout } from 'antd'

import { menuItems, getCurrentRouteID } from './MenuItems'

const Header = (props) => (
	<Layout.Header>
		<Menu
			mode='horizontal'
			defaultSelectedKeys={['0']}
			selectedKeys={getCurrentRouteID(props.pathName)}
		>
			{menuItems('header', props.pathName)}
		</Menu>
	</Layout.Header>
)

export default Header
