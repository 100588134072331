import React from 'react'
import { Link } from 'gatsby'
import { Menu, Icon } from 'antd'

import Logo from './Logo'

const menu = [
	{ title: 'Music', route: '/music', icon: 'sound' },
	{ title: 'Home', logo: true, route: '/' },
	{ title: 'News', route: '/news', icon: 'audit' },
]

export const menuItems = (type, pathName) => {
	console.log(pathName)
	return menu.map((item, index) => {
		const isCurrentRoute = item.route === pathName
		const isSider = type === 'sidebar'
		const disableLink = (e) => {
			isCurrentRoute && e.preventDefault()
		}

		if (isSider && item.logo) return

		return (
			<Menu.Item
				key={index}
				className={`${item.logo ? 'has-img' : ''} ${isCurrentRoute ? 'active' : ''}`}
			>
				<Link to={item.route} className='ant-menu-link' onClick={disableLink}>
					{isSider && <Icon type={item.icon} />}
					{item.logo ? <Logo /> : <p>{item.title}</p>}
				</Link>
			</Menu.Item>
		)
	})
}

export const getCurrentRouteID = (pathName) => {
	const findRoute = (menuItem) => {
		return menuItem.route === pathName
	}
	const routeID = menu.findIndex(findRoute).toString()
	return [routeID]
}
